/**
 * Event handler to submit forms in the background. Returns a promise.
 * - remove all .has-error classes from all elements
 * - post the form to the server using XHR
 * - mark appropriate form element groups with .has-error if response is error
 *
 *
 * @param e
 * @param resetButtonOnSuccess
 */
function backgroundFormSubmit(e, resetButtonOnSuccess) {
	e.preventDefault();
	let $form      = $(e.target),
		$submitBtn = $form.find('[type="submit"],.btn-submit').first(),
		params     = {
			url : $form.attr('data-presubmit-url'),
			data: $form.serialize(),
			type: 'POST',
		};

	$form.removeFormErrors();
	params.context = $form;

	$submitBtn.loading();
	let xhr = $.ajax(params).fail(reportFormErrors);

	xhr.always(function (xhr, status) {
		if (status !== 'success' || resetButtonOnSuccess !== false) {
			$submitBtn.loading(false);
		}
	});

	return xhr;
}


/**
 * 1. Validate form submission in background using the attribute data-prevalidate and a POST request
 * 2. If it returns with a valid object, then resubmit form as normal.
 *    2a. if form is missing a method attribute then a simple redirect to the form's action attribute is used, instead of a submit.
 * @param e

 * @returns {boolean}
 */
function validateAndSubmit(e) {
	console.log('validateAndSubmit');
	let $form = $(e.target),
		xhr   = backgroundFormSubmit(e, false);
	// console.log('validateAndSubmit',$form[0]);
	// perform normal form submit, now:
	xhr.done(function (response) {
		if (response) {
			// console.log('response received');
			$(document).off('submit');
			if (!$form.attr('method')) {
				location.href = $form.attr('action');
			} else {
				setTimeout(function () {
					// console.log('proceeding...');
					$form.submit();
				}, 0);
			}
		}
	});
	return false;
}

/**
 * Set the context of an AJAX request to submit a form, and bind this function as a fail() handler.
 * - mark element's .form-group container with has-error and set the data-error attribute.
 * - scroll document such that the first error element is at the top.
 * - change text of .message element with message received from server.
 *
 * @param xhr
 */

function reportFormErrors(xhr, status, errorMessage) {
    let response = xhr.responseText, errors = null, $form = this;
    try {
		response = $.parseJSON(response);
		errors   = response['errors'];
	} catch (ignore) {

	}

	if (!response || (xhr.status >= 500 && !response.error)) {
		response = {error: 'Unable to process your request at this time. Please try again in a few minutes.'};
	}

	let responseErrorMessage= (typeof response.error!=='undefined')?response.error:response;

	// set 'global' error message for the form (form attribute data-error-indicator=".message", see plugins.js)
	$form.setError(responseErrorMessage);
	let alertMessage='There was a problem processing your request.\n\n';
	if (!errors || !errors.length||errors==={}){
		alertMessage=responseErrorMessage +'\n\n';
	}else {
        $.each(errors, function (fieldName, errorMessage) {
            // console.log(fieldName, ': ', errorMessage);
            let $element = $form.find('[name="' + fieldName + '"]');
            let $wrap = $element.parents('.form-group');
            $element.setError(errorMessage);
            if ($wrap.hasClass('fade') && !$wrap.hasClass('in')) {
                // fixme - lazy hack
                $('#store_banner').trigger('input');
                $wrap.addClass('in');
            }
            alertMessage += errorMessage + '\n';
        });
    }
	if ($('.code-entry-page').length===0) {
		let $firstError = this.find('.has-error').first();
		if ($firstError.length) {
			$firstError[0].scrollIntoView();
			setTimeout(function () {
				let $input = $firstError.find('input,textarea,select').first();
				$input.focus();
			}, 0);
		}
		// alert(alertMessage);
	}
}

/**
 * ZeptoJS / jQuery Plugins
 */

$.extend($.fn, {
	/**
	 * Start/stop a button loading state.
	 * Set window.ButtonAnimationClass to zoom-out or other to determine animation effect. Set window.ButtonAnimationClass to none to completely disable it.
	 * @param {boolean} [showIndicator] - true to show the loading indicator, false to remove it.
	 * @returns {Zepto}
	 *
	 */
	loading: function (showIndicator) {
		// ie8 does not support Ladda - supported only by ie9+
		if (!document.createRange || !this[0]) {
			return this;
		}
		if (typeof Ladda==='undefined'){
            console.error('Ladda.js was not loaded.');
            return this;
		}
		try {
			if (showIndicator === false) {
				Ladda.stopAll();
			} else {
				if (window.ButtonAnimationClass) {
					if (window.ButtonAnimationClass === 'none') {
						return this;
					}
					this.attr('data-style', window.ButtonAnimationClass);
				}
				this.each(function(index, el){
					let l = Ladda.create(el);
					if (l) {
						l.start();
					}else{
						console.log('cannot turn this into a button:',el);
					}
				});
			}
		} catch (ignore) {
			console.log('ladda error:', ignore)
		}
		return this;
	},

	addEmptyFirstOption: function (label) {
		let opt       = document.createElement('option');
		opt.value     = '';
		opt.disabled  = true;
		opt.innerHTML = label || '- Choose One -';
		this.prepend(opt);
		this[0].selectedIndex = 0;
		return this;
	},

	setOptions: function (data, firstOptionTitle) {
		// console.log('setOptions:', this[0]);
		var $el = this;
		this.html('');
		$.each(data, function (value, label) {
			var opt       = document.createElement('option');
			opt.value     = value;
			opt.innerHTML = label;
			$el.append(opt);
		});
		if (firstOptionTitle !== false) {
			this.addEmptyFirstOption(firstOptionTitle);
		}
		this.trigger('change');
		return this;
	},

	removeFormErrors: function () {
		this.setError(false);
		this.find('.has-error').each(function () {
			$(this).find('input, textarea, select').setError(false);
		});
		return this;
	},

	/**
	 * element attribute data-error-indicator can be a CSS selector whose HTML will be replaced with the provided message.
	 * @param message
	 * @returns {Zepto}
	 */
	setError: function (message) {
		// this = the input element to which the error is being attached
		var otherTarget          = this.attr('data-error-indicator'),
			$formGroup           = this[0].tagName == 'FORM' ? this : this.parents('.form-group'),
			$errorMessage        = null,
			$errorMessageWrapper = otherTarget ? $(otherTarget) : null,
			uid                  = this.attr('id');

		if ($errorMessageWrapper) {
			if (message) {
				$errorMessageWrapper.html(message).removeClass('hide');
				this.attr('aria-invalid', 'true').attr('aria-describedby', otherTarget.substr(1));
			} else {
				$errorMessageWrapper.html('').addClass('hide');
				this.removeAttr('aria-invalid aria-describedby');
			}
		} else {
			if (message) {
				// $formGroup.addClass('has-error').attr('data-error', message);
				$formGroup.addClass('has-error');
				$errorMessage = $('#error_' + uid);
				if (!$errorMessage.length) {
					this.after('<div id="error_' + uid + '" class="form-error">' + message + '</div>');
					this.attr('aria-invalid', 'true').attr('aria-describedby', 'error_' + uid);
				} else {

				}
			} else {
				$formGroup.removeClass('has-error');
				this.removeAttr('aria-invalid aria-describedby');
				$formGroup.find('#error_' + uid).remove();
			}
		}
		return this;
	}

});

